import React, { useState, useEffect, useRef } from 'react';
import { useExternalScript } from "../components/helpers/ai-sdk/externalScriptsLoader";
import { getAiSdkControls } from "../components/helpers/ai-sdk/loader";
import FaceTrackerComponent from "../components/morphcast/FaceTrackerComponent";
import EmotionBarsComponent from "../components/morphcast/EmotionsComponent";
import axios from 'axios';
import { PageLayout} from '../components/common/PageLayout';
import './Chatbot.css';
import styled from "styled-components";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import Stack from '@mui/material/Stack';
import { green, red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HfInference } from "@huggingface/inference";



/* styled components */

const ChatbotContainer = styled.div`
    width: 600px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ChatbotMessages = styled.div`
    height: 400px;
    overflow-y: scroll;
    padding: 8px;
`;
  
const ChatbotInputForm = styled.form`
    display: flex;
    margin-top: 16px;

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      font-size: 16px;
    }
    button {
      padding: 8px 16px;
      border: none;
      background-color: #007bff;
      color: #fff;
      border-radius: 0 4px 4px 0;
      font-size: 16px;
      cursor: pointer;
    }
    button:hover {
    background-color: #0056b3;
  }
`;

// Create a styled component for the main container
const Container = styled.div`
  display: flex;
`;

// Create a styled component for each column
const Column = styled.div`
  flex: 1;
  padding: 20px;
`;

/* styled components */

const OpenAI = require('openai');
const Chatbot = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

const chatWithGPT35 = async (userInput) => {
    const apiEndpoint = 'https://api.openai.com/v1/engines/davinci-codex/completions';
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
      'mode':'no-cors',
      'Content-Type': 'application/json',
      'Authorization': `Bearer chatGPTKey`
    };

    const data = {
      prompt: userInput,
      max_tokens: 150
    };
try {
      const response = await axios.post(apiEndpoint, data, { headers });
      return response.data.choices[0].text.trim();
    } catch (error) {
      console.error('Error communicating with the API:', error.message);
      return '';
    }
  };

  const chatWithGPT4 = async (data) => {
    const openai = new OpenAI(
        {
            apiKey: "",
            dangerouslyAllowBrowser: true 
        });
        return chatMessage(openai, data, 'gpt-4-1106-preview');
}

const chatWithLLama3Local = async (data) => {
  const openai = new OpenAI(
    {
        baseURL: 'https://6b468639885b.ngrok.app/v1',
        apiKey: "not-needed",
        dangerouslyAllowBrowser: true 
    });
    return chatMessage(openai, data, 'local-model');
}


const chatWithDolphinMixtralHuggingace = async (data) => {
    const openai = new OpenAI(
      {
          baseURL: "https://api-inference.huggingface.co/models/NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO",//"https://api-inference.huggingface.co/models/mistralai/Mistral-7B-v0.1",
          apiKey: "",
          dangerouslyAllowBrowser: true 
      });
      return chatMessage(openai, data, 'mistralai/Mistral-7B-v0.1');
}

const chatWithDolphinMistralInstruct02Huggingace = async (data) => {
    const openai = new OpenAI(
      {
          baseURL: "https://api-inference.huggingface.co/models/mistralai/Mistral-7B-Instruct-v0.2",
          apiKey: "",
          dangerouslyAllowBrowser: true 
      });
      return chatMessage(openai, data, 'mistralai/Mistral-7B-Instruct-v0.2');
}

async function query(data) {
	const response = await fetch(
    "https://api-inference.huggingface.co/models/unsloth/llama-3-8b-bnb-4bit",
    //"https://6b468639885b.ngrok.app/v1",
    //"https://api-inference.huggingface.co/models/meta-llama/Meta-Llama-3-70B-Instruct",
//    "https://api-inference.huggingface.co/models/meta-llama/Llama-2-7b-chat-hf",
    //"https://api-inference.huggingface.co/models/mistralai/Mistral-7B-Instruct-v0.2",
//    "https://api-inference.huggingface.co/models/NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO",
    //"https://api-inference.huggingface.co/models/mistralai/Mistral-7B-v0.1",
//		"https://api-inference.huggingface.co/models/mistralai/Mixtral-8x7B-Instruct-v0.1",
//'Content-Type': 'application/json',
{
			headers: { 
        Authorization: "Bearer hf_",
        'Content-Type': 'application/json'
      },
			method: "POST",
			body: JSON.stringify(data),
		}
	);
	const result = await response.json();
	return result;
}


async function queryHFSpace(userInput) {
  const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
/*
const raw = JSON.stringify({
  "chat": {
    "message": userInput,
    "request": "System message e.g. You are a friendly Chatbot. You always give a new joke different from the previous one",
    "max_new_tokens_p3": 512,
    "temperature_p4": 0.7,
    "top_p_p5": 0.95
  }
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("http://localhost:3001/api/cv", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
*/     const apiEndpoint = 'https://confidencevote.symphysiis.com:3443/api/cv';
    //const apiEndpoint = 'http://localhost:3001/api/cv';
  
    const data = {
      "chat": {
          "message": userInput,
          "request": "System message e.g. You are a friendly Chatbot. You always give a new joke different from the previous one",
          "max_new_tokens_p3": 512,
          "temperature_p4": 0.7,
          "top_p_p5": 0.95
      }
    }

  try {
    const response = await axios.post(apiEndpoint, data, {
      headers: myHeaders
  });
    console.log(response);
    return response.data[0]
  } catch (error) {
    console.error('Error communicating with the API:', error.message);
    return '';
  }
   
}


    const chatMessage = async (openai, data, model) => {
    try {
        const response = await openai.chat.completions.create({
            messages: [
              {"role": "system", "content": "you are an agile coach and you want to assess my confidence in my sprint tickets, my mood is depressed"},
              { role: 'user', content: data }
            ],
            model: model
        });
        return response.choices[0].message.content;
      } catch (error) {
        console.error('Error communicating with the API:', error.message);
        return 'error!!!';
      }

}


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    const userMessage = { text: input, user: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    const aiMessage = { text: '...', user: false };
    setMessages((prevMessages) => [...prevMessages, aiMessage]);
    //const response = await chatWithGPT4(input);
    //chatWithGPT3(input);
    //chatWithGPT4(input);
    //const response = await chatWithDolphinMixtralHuggingace(input);
    //const response = await chatWithDolphinMistralInstruct02Huggingace(input);
    //const response = await chatWithLLama3Local(input);
    //const response = await query({ inputs: input });

    const response = await queryHFSpace( input );
    const newAiMessage = { text: response, user: false };
    //const newAiMessage = { text: response[0].generated_text, user: false };
    setMessages((prevMessages) => [...prevMessages.slice(0, -1), newAiMessage]);
    setInput('');
  };

  /*** MORPHCAST ***/
  const mphToolsState = useExternalScript("https://sdk.morphcast.com/mphtools/v1.0/mphtools.js");
  const aiSdkState = useExternalScript("https://ai-sdk.morphcast.com/v1.16/ai-sdk.js");
  const videoEl = useRef(undefined);
  const [timer, setTimer] = useState(0); 
  const firstStart = useRef(true);
  const tick = useRef();
  const [toStart, setToStart] = useState(false);
  const dispSecondsAsMins = (seconds) => {
    console.log("seconds " + seconds);
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return mins.toString() + ":" + (seconds_ == 0 ? "0" : seconds_.toString());
  };

  /*useEffect(() => {
    if (firstStart.current) {
      console.log("first render, don't run useEffect for timer");
      firstStart.current = !firstStart.current;
      return;
    }

    console.log("subsequent renders");
    console.log(toStart);
    if (toStart) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      console.log("clear interval");
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [toStart]);
*/
  const toggleStart = () => {
    setToStart(!toStart);
  };

  useEffect(() => {
    videoEl.current = document.getElementById("videoEl");
    async function getAiSdk (){
      if(aiSdkState === "ready" && mphToolsState === "ready"){
        const { source, start, stop } = await getAiSdkControls();
      await source.useCamera({
        toVideoElement: document.getElementById("videoEl"),
      });
        if(toStart){
          await start();
        } else {
          await stop();
        }
      }
    }
    getAiSdk();
  }, [aiSdkState, mphToolsState, toStart]);


  const buttonTheme = createTheme({
    palette: {
      primary: green,
      secondary: red,
    },
  });
/*** MORPHCAST ***/
  return (
    <ThemeProvider theme={buttonTheme}>
    <Container>
      <Column>    
    <PageLayout>
    <h2>Welcome to ConfidenceVote</h2>
    <div className="chatbot-container">
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.user ? 'user-message' : 'ai-message'}`}
          >
            <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
          </div>
        ))}
      </div>
      <ChatbotInputForm onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </ChatbotInputForm>
    </div>
    </PageLayout>
    </Column>
    <Column>
    <p/>
    <p>.</p>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={toggleStart} endIcon={toStart? <StopIcon />:<SendIcon />} color={toStart? 'secondary' : 'primary'}>
        {toStart? 'Stop' : 'Start'}
      </Button>
      <h1>{dispSecondsAsMins(timer)}</h1>
    </Stack>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Emotions Screen
        </AccordionSummary>
        <AccordionDetails>
        <Stack direction="row" spacing={2}>
          <Paper elevation={6}>
            <div style={{width:"640px", height: "800px", position:"relative"}}>
              <video id="videoEl"></video>
              <FaceTrackerComponent videoEl={videoEl}></FaceTrackerComponent>
              <EmotionBarsComponent></EmotionBarsComponent>
            </div>
        </Paper>
    </Stack>    
        </AccordionDetails>
      </Accordion>
      
    </Column>
    </Container>
    </ThemeProvider>
  );
};


export default Chatbot;
