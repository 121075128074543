import styled, {css} from "styled-components";

const Container = styled.div`
  background-color: #FFFDD0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`;

export {Container};