import { useState, useEffect } from "react";
import "./componentCSS/emotionBarsComponent.css"
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';


const EmotionComponent = () => {
  // barchart
  const xLabels = [
    'Depressed',
    'Sad',
    'Anxious',
    'Disgusted',
    'Angry',
    'Confident',
    'Friendly',
    'Happy',
    'Enthusiastic',
    'Triumphant',
    'Conceited'
  ];

  
  const [DepressedValues, setDepressedValues] = useState([]);
  const [DepressedAverage, setDepressedAverage] = useState(null);
  const [SadValues, setSadValues] = useState([]);
  const [SadAverage, setSadAverage] = useState(null);
  const [AnxiousValues, setAnxiousValues] = useState([]);
  const [AnxiousAverage, setAnxiousAverage] = useState(null);
  const [DisgustedValues, setDisgustedValues] = useState([]);
  const [DisgustedAverage, setDisgustedAverage] = useState(null);
  const [AngryValues, setAngryValues] = useState([]);
  const [AngryAverage, setAngryAverage] = useState(null);
  const [ConfidentValues, setConfidentValues] = useState([]);
  const [ConfidentAverage, setConfidentAverage] = useState(null);
  const [FriendlyValues, setFriendlyValues] = useState([]);
  const [FriendlyAverage, setFriendlyAverage] = useState(null);
  const [HappyValues, setHappyValues] = useState([]);
  const [HappyAverage, setHappyAverage] = useState(null);
  const [EnthusiasticValues, setEnthusiasticValues] = useState([]);
  const [EnthusiasticAverage, setEnthusiasticAverage] = useState(null);
  const [TriumphantValues, setTriumphantValues] = useState([]);
  const [TriumphantAverage, setTriumphantAverage] = useState(null);
  const [ConceitedValues, setConceitedValues] = useState([]);
  const [ConceitedAverage, setConceitedAverage] = useState(null);

  const [affectData, setAffectData] = useState([]);
  const [affectedTotalValues, setAffectedTotalValues] = useState(new Array(11).fill(0));

  // Function to add or update affectData
  const addAffect = (affect, value) => {
    // Check if the affect already exists in the data array
    const existingIndex = affectData.findIndex(item => item.affect === affect);

    if (existingIndex !== -1) {
      // Update the existing entry
      affectData[existingIndex].value = value;
    } else {
      // Create a new entry
      const newData = {
        affect,
        value,
        total: 0 // Initialize total with 0
      };
      affectData.push(newData);
    }
  };


  const getValueForAffect = (affect) => {
    const existingIndex = affectData.findIndex(item => item.affect === affect);
    return affectData[existingIndex].value;
  };

  const updateTotalForAffect = (affect, newValue) => {
    if( newValue !== undefined){
        const existingIndex = affectData.findIndex(item => item.affect === affect);
    
        if (existingIndex !== -1) {
          var position = 5 + affectData[existingIndex].value;
          affectedTotalValues[position] = affectedTotalValues[position] + newValue;
          console.log(`Total value for affect '${affectData[existingIndex].value} : ` + newValue);
        } else {
          console.log(`Affect '${affect}' not found.`);
        }
      }
    };

  useEffect(() => {

    /*for (let i in names) {
      console.log(names[i]);
    }*/
    addAffect('Depressed', -5);
    addAffect('Sad', -4);
    addAffect('Anxious', -3);
    addAffect('Disgusted', -2);
    addAffect('Angry', -1);
    addAffect('Confident', 0);
    addAffect('Friendly', 1)
    addAffect('Happy', 2);
    addAffect('Enthusiastic', 3);
    addAffect('Triumphant', 4);
    addAffect('Conceited', 5);

    const handleAgeEvent = (evt) => {
      
      const receivedValueDepressed = evt.detail.output.affects98.Depressed;// * 100;
      setDepressedValues((prevValues) => [...prevValues, receivedValueDepressed]);
      const receivedValueSad = evt.detail.output.affects98.Sad;// * 100;
      setSadValues((prevValues) => [...prevValues, receivedValueSad]);
      const receivedValueAnxious = evt.detail.output.affects98.Anxious;// * 100;
      setAnxiousValues((prevValues) => [...prevValues, receivedValueAnxious]);
      const receivedValueDisgusted = evt.detail.output.affects98.Disgusted;// * 100;
      setDisgustedValues((prevValues) => [...prevValues, receivedValueDisgusted]);
      const receivedValueAngry = evt.detail.output.affects98.Angry;// * 100;
      setAngryValues((prevValues) => [...prevValues, receivedValueAngry]);
      const receivedValueConfident = evt.detail.output.affects98.Confident;// * 100;
      setConfidentValues((prevValues) => [...prevValues, receivedValueConfident]);
      const receivedValueFriendly = evt.detail.output.affects98.Friendly;// * 100;
      setFriendlyValues((prevValues) => [...prevValues, receivedValueFriendly]);
      const receivedValueHappy = evt.detail.output.affects98.Happy;// * 100;
      setHappyValues((prevValues) => [...prevValues, receivedValueHappy]);
      const receivedValueEnthusiastic = evt.detail.output.affects98.Enthusiastic;// * 100;
      setEnthusiasticValues((prevValues) => [...prevValues, receivedValueEnthusiastic]);
      const receivedValueTriumphant = evt.detail.output.affects98.Triumphant;// * 100;
      setTriumphantValues((prevValues) => [...prevValues, receivedValueTriumphant]);
      const receivedValueConceited = evt.detail.output.affects98.Conceited;// * 100;
      setConceitedValues((prevValues) => [...prevValues, receivedValueConceited]);
    };

    setDepressedValues([]);
    setSadValues([]);
    setAnxiousValues([]);
    setDisgustedValues([]);
    setAngryValues([]);
    setConfidentValues([]);
    setFriendlyValues([]);
    setHappyValues([]);
    setEnthusiasticValues([]);
    setTriumphantValues([]);
    setConceitedValues([]);

    window.addEventListener("CY_FACE_AROUSAL_VALENCE_RESULT",handleAgeEvent);
    
    return () => {
      window.removeEventListener('CY_FACE_AROUSAL_VALENCE_RESULT', handleAgeEvent);
    };
  }, []); 
  /************* USEEffects ****************/
  useEffect(() => {
    const total = DepressedValues.reduce((acc, val) => acc + val, 0);
    const newAverage = DepressedValues.length > 0 ? total / DepressedValues.length : null;

    setDepressedAverage(newAverage);
    updateTotalForAffect('Depressed', DepressedValues[DepressedValues.length-1]);
    console.log('Face emotion result: Depressed %f', DepressedAverage);
  }, [DepressedValues, DepressedAverage]);

  useEffect(() => {
    const total = SadValues.reduce((acc, val) => acc + val, 0);
    const newAverage = SadValues.length > 0 ? total / SadValues.length : null;

    setSadAverage(newAverage);
    updateTotalForAffect('Sad', SadValues[SadValues.length-1]);
    console.log('Face emotion result: Sad %f', SadAverage);
  }, [SadValues, SadAverage]);

  useEffect(() => {
    const total = AnxiousValues.reduce((acc, val) => acc + val, 0);
    const newAverage = AnxiousValues.length > 0 ? total / AnxiousValues.length : null;

    setAnxiousAverage(newAverage);
    updateTotalForAffect('Anxious', AnxiousValues[AnxiousValues.length-1]);
    console.log('Face emotion result: Anxious %f', AnxiousAverage);
  }, [AnxiousValues, AnxiousAverage]);
  
  useEffect(() => {
    const total = DisgustedValues.reduce((acc, val) => acc + val, 0);
    const newAverage = DisgustedValues.length > 0 ? total / DisgustedValues.length : null;

    setDisgustedAverage(newAverage);
    updateTotalForAffect('Disgusted', DisgustedValues[DisgustedValues.length-1]);
    console.log('Face emotion result: Disgusted %f', DisgustedAverage);
  }, [DisgustedValues, DisgustedAverage]);

  useEffect(() => {
    const total = AngryValues.reduce((acc, val) => acc + val, 0);
    const newAverage = AngryValues.length > 0 ? total / AngryValues.length : null;

    setAngryAverage(newAverage);
    updateTotalForAffect('Angry', AngryValues[AngryValues.length-1]);
    console.log('Face emotion result: Angry %f', AngryAverage);
  }, [AngryValues, AngryAverage]);
  
  useEffect(() => {
    const total = ConfidentValues.reduce((acc, val) => acc + val, 0);
    const newAverage = ConfidentValues.length > 0 ? total / ConfidentValues.length : null;

    setConfidentAverage(newAverage);
    updateTotalForAffect('Confident', ConfidentValues[ConfidentValues.length-1]);
    console.log('Face emotion result: Confident %f', ConfidentAverage);
  }, [ConfidentValues, ConfidentAverage]);
  
  useEffect(() => {
    const total = FriendlyValues.reduce((acc, val) => acc + val, 0);
    const newAverage = FriendlyValues.length > 0 ? total / FriendlyValues.length : null;

    setFriendlyAverage(newAverage);
    updateTotalForAffect('Friendly', FriendlyValues[FriendlyValues.length-1]);
    console.log('Face emotion result: Friendly %f', FriendlyAverage);
  }, [FriendlyValues, FriendlyAverage]);

  useEffect(() => {
    const total = HappyValues.reduce((acc, val) => acc + val, 0);
    const newAverage = HappyValues.length > 0 ? total / HappyValues.length : null;

    setHappyAverage(newAverage);
    updateTotalForAffect('Happy', HappyValues[HappyValues.length-1]);
    console.log('Face emotion result: Happy %f', HappyAverage);
  }, [HappyValues, HappyAverage]);

  useEffect(() => {
    const total = EnthusiasticValues.reduce((acc, val) => acc + val, 0);
    const newAverage = EnthusiasticValues.length > 0 ? total / EnthusiasticValues.length : null;

    setEnthusiasticAverage(newAverage);
    updateTotalForAffect('Enthusiastic', EnthusiasticValues[EnthusiasticValues.length-1]);
    console.log('Face emotion result: Enthusiastic %f', EnthusiasticAverage);
  }, [EnthusiasticValues, EnthusiasticAverage]);

  useEffect(() => {
    const total = TriumphantValues.reduce((acc, val) => acc + val, 0);
    const newAverage = TriumphantValues.length > 0 ? total / TriumphantValues.length : null;

    setTriumphantAverage(newAverage);
    updateTotalForAffect('Triumphant', TriumphantValues[TriumphantValues.length-1]);
    console.log('Face emotion result: Triumphant %f', TriumphantAverage);
  }, [TriumphantValues, TriumphantAverage]);

  useEffect(() => {
    const total = ConceitedValues.reduce((acc, val) => acc + val, 0);
    const newAverage = ConceitedValues.length > 0 ? total / ConceitedValues.length : null;

    setConceitedAverage(newAverage);
    updateTotalForAffect('Conceited', ConceitedValues[ConceitedValues.length-1]);
    console.log('Face emotion result: Conceited %f', ConceitedAverage);
  }, [ConceitedValues, ConceitedAverage]);
  
  return (
    <>
        <div style={{fontSize:"20px"}}>EmotionBars Component:</div>
        <div id="emotionsContainer">
        {/* <PieChart
          series={[{
            arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
            data: [
              { id: 0, value: Math.floor(angryAverage), label: 'Angry' },
               { id: 1, value: Math.floor(disgustAverage), label: 'Disgusted' },
              { id: 2, value: Math.floor(EnthusiasticAverage), label: 'Enthusiastic' }, 
            ],},
          ]}
          width={400}
          height={200}
        /> */}
        <BarChart
      series={[
        { data: affectedTotalValues, label: 'Affects', id: 'uvId' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
      width={800}
      height={500}
    />
        
    </div>
    </>
   
  );
};

export default EmotionComponent;
