import styled, {css} from "styled-components";

const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #f1f1f1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  li {
    margin: 0 10px;
  }
`;

export {Menu};
