// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  margin: 8px 0;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
}

.user-message {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}


.ai-message {
  background-color: #f0f0f0;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Chatbot.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;AACnB;;;AAGA;EACE,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".message {\n  margin: 8px 0;\n  padding: 8px;\n  border-radius: 4px;\n  font-size: 16px;\n  line-height: 1.5;\n}\n\n.user-message {\n  background-color: #007bff;\n  color: #fff;\n  text-align: right;\n}\n\n\n.ai-message {\n  background-color: #f0f0f0;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
