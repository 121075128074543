import { PageLayout} from '../components/common/PageLayout';
import { Input} from '../components/common/Input';
import { PasswordInput} from '../components/common/PasswordInput';
import { Button} from '../components/common/Button';
import { LoadingSpinner} from '../components/common/LoadingSpinner';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from 'styled-components';
import { storeUser } from '../components/common/Helpers';
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';
import { Container} from '../components/common/Container';
import { Menu} from '../components/common/Menu';
import { MenuButton} from '../components/common/MenuButton';

/****** NEW *******/

const FormContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
  margin-top: 80px;
`;

const Header = styled.div`
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  h2 {
    margin: 0;
  }
`;

const Form = styled.form`
  padding: 20px;
`;

const FormControl = styled.div`
  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;

  label {
    display: inline-block;
    margin-bottom: 5px;
  }

  input {
    border: 2px solid ${props => props.error ? '#e74c3c' : props.success ? '#2ecc71' : '#f0f0f0'};
    border-radius: 4px;
    display: block;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    
    &:focus {
      outline: none;
      border-color: #777;      
    }
  }

  i {
    visibility: ${props => props.error || props.success ? 'visible' : 'hidden'};
    position: absolute;
    top: 40px;
    right: 10px;
    color: ${props => props.error ? '#e74c3c' : '#2ecc71'};
  }

  small {
    color: #e74c3c;
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: ${props => props.error ? 'visible' : 'hidden'};
  }
`;

const SubmitButton = styled.button`
  background-color: #8e44ad;
  border: 2px solid #8e44ad;
  border-radius: 50px;
  color: #fff;
  display: block;
  font-size: 16px;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #732d91;
    border-color: #732d91;
    transform: scale(1.02);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  }
`;
/******************/
/****** OLD *******/
/******************/
// const Form = styled.form`
//     width: 100%;
//     max-width: 400px;
//     background: white;
//     border: 1px solid #eee;
//     padding: 16px;
//     box-sizing: border-box;
//     color: black;
//     border-radius: 4px;

//     .alt-text{
//         text-align: center;
//         margin: 10px 0;
//     }
//     ${Button}{
//         margin-top: 10px;
//     }
// `;

const STRAPI_AUTH_URL=process.env.REACT_APP_STRAPI_AUTH_URL;
const initialUser = { password: "", identifier: "" };
let timeout;

const Login = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  
    const [loading, setLoading] = useState(false);


    const gotoRegister = () => {
      navigate("/Register");
    };

    const buttonTheme = createTheme({
      palette: {
        primary: blue,
        secondary: red,
      },
    });

    /******************/
    /****** NEW *******/
    /******************/
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if(!isValidEmail(user.identifier)) {
        setEmailError('Provide a valid email address'); 
        return;
      } else {
        setEmailError('');
      }
      
      if(user.password.length < 6) {
        setPasswordError('Password must be at least 8 characters');
        return;
      } else {
        setPasswordError('');  
      }
      
      const url = STRAPI_AUTH_URL + "/api/auth/local";
      try {
       if (user.identifier && user.password) {
        const { data } = await axios.post(url, user);
        if (data.jwt) {
          storeUser(data);
          /*toast.success("Logged in successfully!", {
            hideProgressBar: true,
          });*/
          setUser(initialUser);
          navigate("/main");
        }
       }
    } catch (error) {
      toast.error(error.message, {
        hideProgressBar: true,
      });
    }
    };
    
    const isValidEmail = email => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());  
    };
    
    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };
    
    return (
      <Container>
        <Menu>
          <li><MenuButton href="login">Login</MenuButton></li>  
          <li><MenuButton href="signin">Sign In</MenuButton></li>
          <li><MenuButton href="main">Main Page</MenuButton></li>
          <li><MenuButton href="logout">Logout</MenuButton></li>
        </Menu>
        
        <FormContainer>
          <Header>
            <h2>Confidence Vote Login</h2>
          </Header>
          <Form onSubmit={handleSubmit}>
            <FormControl error={emailError} success={email && !emailError}>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="email@example.com"
                onChange={handleChange}
                name="identifier"
                value={user.identifier}
              />
              <i className={`fas ${emailError ? 'fa-exclamation-circle' : 'fa-check-circle'}`}></i>
              <small>{emailError}</small>
            </FormControl>
  
            <FormControl error={passwordError} success={password && !passwordError}>
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={user.password}
                onChange={handleChange} 
                placeholder="Password"
              />
              <i className={`fas ${passwordError ? 'fa-exclamation-circle' : 'fa-check-circle'}`}></i>
              <span onClick={toggleShowPassword}>
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </span>
              <small>{passwordError}</small>
            </FormControl>
  
            <SubmitButton type="submit">Submit</SubmitButton>
          </Form>
        </FormContainer>
      </Container>
    );
  }
  

export default Login;