// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#emotionsContainer {
    position: relative;
    width: 702px;
    height: 141px;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    border-radius: 1px;
  }
  .big {
    height: 210px !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/morphcast/componentCSS/emotionBarsComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,UAAU;IACV,kBAAkB;EACpB;EACA;IACE,wBAAwB;EAC1B","sourcesContent":["#emotionsContainer {\n    position: relative;\n    width: 702px;\n    height: 141px;\n    background: rgba(0, 0, 0, 0);\n    opacity: 1;\n    border-radius: 1px;\n  }\n  .big {\n    height: 210px !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
