// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#faceTracker {
    position: absolute;
    border: 2px solid #ff871d3b;
  }

#faceTrackerContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/morphcast/componentCSS/faceTrackerComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2BAA2B;EAC7B;;AAEF;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,OAAO;IACP,MAAM;AACV","sourcesContent":["#faceTracker {\n    position: absolute;\n    border: 2px solid #ff871d3b;\n  }\n\n#faceTrackerContainer{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
