import styled, {css} from "styled-components";

const largeStyles = ({large}) => {
    if(large){
        return css`
            padding: 12px;
            border-radius: 8px;
            font-size: 2em;
        `;
    } else {
        return css`
            padding: 8px;
            border-radius: 4px;
            font-size: 1em;
    `;
    }

}

const Button = styled.button`
    color: white;
    background: ${p => p.secondary ?  p.theme.secondaryButtonColor: p.theme.primaryButtonColor};
    font-weight: bold;
    ${largeStyles};
    box-shadow: none;
    border: none;
    width: 100%;
    display: block;
    white-space: none;
    color: blue;

    &:disabled{
        background: #eee;
        color: 555;
    }

    &:hover {
    cursor: pointer;
  }
`
export {Button};