import React, { useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";

export const storeUser = (data) => {
  localStorage.setItem(
    "user",
    JSON.stringify({
      username: data.user.username,
      jwt: data.jwt,
      userid: data.user.id,
    })
  );
};

export const userData = () => {
  const stringifiedUser = localStorage.getItem("user") || '""';
  return JSON.parse(stringifiedUser || {});
};


export const Protector = () => {
  const { jwt } = userData();

  // Check if the user is authenticated
  if (!jwt) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }
  else {
  // If authenticated, render the child routes
  return <Outlet />;
  }
};

