import { PageLayout} from '../components/common/PageLayout';
import { Input} from '../components/common/Input';
import { PasswordInput} from '../components/common/PasswordInput';
import { Button} from '../components/common/Button';
import { LoadingSpinner} from '../components/common/LoadingSpinner';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from 'styled-components';
import { storeUser } from '../components/common/Helpers';
import { toast } from "react-toastify";

const Form = styled.form`
    width: 100%;
    max-width: 400px;
    background: white;
    border: 1px solid #eee;
    padding: 16px;
    box-sizing: border-box;
    color: black;
    border-radius: 4px;

    .alt-text{
        text-align: center;
        margin: 10px 0;
    }
    ${Button}{
        margin-top: 10px;
    }
`;

const initialUser = { email: "", password: "", username: "" };

//let timeout;

const Register = () => {
    const [user, setUser] = useState(initialUser);
    const navigate = useNavigate();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  const gotoLogin = () => {
    navigate("/login");
  }

  const handleRegister = async (e) => {
    const url = `https://peppix.com:2443/api/auth/local/register`;
    try {
        e.preventDefault()

        user.username = user.email;
        if (user.username && user.email && user.password) {
            const { data } = await axios.post(url, user);
            if (data.jwt) {
                storeUser(data);
                toast.success("Registered successfully!", {
                  hideProgressBar: true,
            });  
            setUser(initialUser);
          navigate("/main");
        }
      }
    } catch (error) {
    
      toast.error(error.response.data.error.message, {
        hideProgressBar: true,
      });
    }
  };
    const [loading, setLoading] = useState(false);

    return(
        <PageLayout>
            <h1>
                Register
            </h1>
            <Form>
                {loading ? <LoadingSpinner /> :
                <>
                <Input 
                    onChange={handleChange}
                    type='text'
                    placeholder='Email'
                    name="email"
                    value={user.email}
                />
                <PasswordInput 
                    onChange={handleChange}
                    type='password'
                    name="password" 
                    placeholder='Password'
                    value={user.password}
                />
                </>
                }
                <Button large type="submit" disabled={loading} onClick={handleRegister}>
                    {loading ? 'Loading...' : 'Register'}
                </Button>
                {!loading &&
                <>
                <div className="alt-text">
                    or
                </div>
                <Button secondary type ="button" onClick={gotoLogin}>
                    Login
                </Button>
                </>
                }
            </Form>
        </PageLayout>
    )
};

export default Register;