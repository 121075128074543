import styled, {css} from "styled-components";

const MenuButton = styled.a`
  display: inline-block;
  color: #333;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ddd;
    transform: scale(1.1);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  }
`;

export {MenuButton}