import Chatbot from '../pages/Chatbot';
import { ToastContainer } from "react-toastify";
import Logout from "../pages/Logout";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Main from "../pages/Main";
import Register from "../pages/Register";
import { Protector } from "./common/Helpers";
import {BrowserRouter, Routes, Route,} from "react-router-dom";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

function App() {
  return (
    <BrowserRouter>   
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/login' element={<Login/>} />
          <Route path="/logout" element={<Logout />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route element={<Protector />}>
            <Route path="/chat" element={<Chatbot />} />
            <Route path="/main" element={<Main />} />
          </Route>
          </Routes>
        </BrowserRouter>
  );
}
export default App;
