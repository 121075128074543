import React, {useState, useContext} from "react";
import styled from 'styled-components';
import { PageLayout} from '../components/common/PageLayout';
import { Link, useLocation} from "react-router-dom";
import { Container} from '../components/common/Container';
import { Menu} from '../components/common/Menu';
import { MenuButton} from '../components/common/MenuButton';

const StyledLink = styled(Link)`
    padding: 4px 8px;
    display: block;
    text-align: center;
    box-sizing: border-box;
    margin: auto 0;
    font-weight: ${p => p.isActive ? 'bold' : 'normal'};
    color: ${p => p.theme.bodyFontColor};    
`


const Main = () => {
    const {pathname} = useLocation();
    return(
        <Container>
        <Menu>
          <li><MenuButton href="login">Login</MenuButton></li>  
          <li><MenuButton href="signin">Sign In</MenuButton></li>
          <li><MenuButton href="main">Main Page</MenuButton></li>
          <li><MenuButton href="logout">Logout</MenuButton></li>
        </Menu>
        <PageLayout>
            <h1>
                Welcome to Confidence Vote
            </h1>
                <>
                </>
                <StyledLink to="/chat" isActive={pathname === '/chat'}>
                Chatbot
                </StyledLink>
        </PageLayout>
        </Container>
        
    );
};

export default Main;