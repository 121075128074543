import { PageLayout } from '../components/common/PageLayout';
import React from 'react';

export default function Home(){
    return(
        <PageLayout>
            <h1>Welcome to Confidence Vote</h1>
            <p>
            @Drew @peppix 
            </p>
        </PageLayout>
    );
}